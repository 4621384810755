import React from 'react';
import PropTypes from 'prop-types';
import { Button, Popover, Row, Divider } from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import FooterComponent from '../FooterComponent';
import FooterComponentLogo from '../FooterComponentLogo';
import LinkedButton from '../LinkedButton';
import config from '../../Config';

import googlePlayBadge from '../../images/google-play-badge.png';
import appStoreBadge from '../../images/app-store-badge.png';

import styles from './styles.module.scss';

import downloadIcon from '../../images/download.png';
import CustomForwoodDownload from '../../Containers/CustomForwoodDownload';

export function DownloadPopupContainer(props) {
  const { t, showStoreButtons, showQRCode, auth } = props;
  const { crmDacRoles, safetyPlusDacRoles, currentUser } = auth;
  const hasOneOfRole = (rolesList) => currentUser.roles.some((role) => rolesList.includes(role));
  const hasCrmDacRoles = hasOneOfRole(crmDacRoles);
  const hasSafetyPlusDacRoles = hasOneOfRole(safetyPlusDacRoles);
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem', textAlign: 'center' }}>
      {hasCrmDacRoles && <DownloadPopupContent title={t('forwoodDownload:ccv')} showStoreButtons={showStoreButtons} showQRCode={showQRCode} hrefAnroid={config.reactApp.CVC_ANDROID} hrefios={config.reactApp.CVC_IOS} qrCode={`${config.reactApp.WEB_URL}/#downloadRedirect`} />}
      {hasCrmDacRoles && hasSafetyPlusDacRoles && <Divider type="vertical" style={{ height: 'auto', width: '1px' }} />}
      {hasSafetyPlusDacRoles && <DownloadPopupContent title={t('forwoodDownload:safetyPlus')} showStoreButtons={showStoreButtons} showQRCode={showQRCode} hrefAnroid={config.reactApp.SAFETYPLUS_ANDROID} hrefios={config.reactApp.SAFETYPLUS_IOS} qrCode={`${config.reactApp.WEB_URL}/#safetyPlusDownloadRedirect`} />}
    </div>
  );
}

export function DownloadPopupContent(props) {
  const { showStoreButtons, showQRCode, hrefAnroid, hrefios, title, qrCode } = props;

  return (
    <div className={styles.container}>
      <h2>{title}</h2>
      {showQRCode && <QRCodeCanvas value={qrCode} />}
      {showStoreButtons && (
        <div className={styles.container}>
          <FooterComponentLogo
            src={googlePlayBadge}
            href={hrefAnroid}
            styles={{ marginBottom: '.5rem' }}
            imageStyles={{ width: '100%' }}
          />
          <FooterComponentLogo
            src={appStoreBadge}
            href={hrefios}
            imageStyles={{ width: '100%' }}
          />
        </div>
      )}
    </div>
  );
}

export function DownloadButton(props) {
  const { popoverVisible, setPopoverVisible, t, settings, auth } = props;
  const { showStoreButtons, showQRCode } = settings.download;

  if (navigator.userAgent.match(/Android/i)) {
    return <LinkedButton href={config.reactApp.CVC_ANDROID} title={t('forwoodDownload:button')} />;
  }

  if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
    return <LinkedButton href={config.reactApp.CVC_IOS} title={t('forwoodDownload:button')} />;
  }

  const translationVariant = config.reactApp.TRANSLATION_VARIANT || '';
  const popupContent = `<div class="ql-align-center">${t(`forwoodDownload:content${translationVariant}`)}</div>`;

  return (
    <Row style={{ marginTop: '1rem' }}>
      <Popover
        content={
          <DownloadPopupContainer
            auth={auth}
            t={t}
            showStoreButtons={showStoreButtons}
            showQRCode={showQRCode}
          />
        }
        title={<div dangerouslySetInnerHTML={{ __html: popupContent }} />}
        trigger="click"
        visible={popoverVisible}
        onVisibleChange={setPopoverVisible}
      >
        <Button>{t('forwoodDownload:button')}</Button>
      </Popover>
    </Row>
  );
}

export default function ForwoodDownload(props) {
  const { t, settings, isAdmin, auth } = props;
  const { forwoodDownload, setFDPopoverVisible, setCustomFDDrawerVisible } = props;

  const windowLocation = window.location.hash;
  const downloadLocation = window.location.hash === '#downloadPopover';
  const popoverVisible = downloadLocation || forwoodDownload.popover.visible;

  if (windowLocation) window.history.replaceState(null, null, ' ');

  return (
    <FooterComponent
      title={t('forwoodDownload:title')}
      description={t('forwoodDownload:desc')}
      logo={<FooterComponentLogo src={downloadIcon} />}
      handlerComponent={(
        <DownloadButton
          popoverVisible={popoverVisible}
          setPopoverVisible={setFDPopoverVisible}
          settings={settings}
          t={t}
          auth={auth}
        />
      )}
      customComponent={<CustomForwoodDownload title={t('contactSupport:title')} t={t} />}
      setCustomVisible={setCustomFDDrawerVisible}
      isAdmin={isAdmin}
    />
  );
}
DownloadButton.propTypes = {
  t: PropTypes.func.isRequired,
  popoverVisible: PropTypes.bool.isRequired,
  setPopoverVisible: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

ForwoodDownload.propTypes = {
  t: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  forwoodDownload: PropTypes.object.isRequired,
  setFDPopoverVisible: PropTypes.func.isRequired,
  setCustomFDDrawerVisible: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

DownloadPopupContainer.propTypes = {
  t: PropTypes.func.isRequired, // Translation function, required
  showStoreButtons: PropTypes.bool.isRequired, // Added validation for 'showStoreButtons'
  showQRCode: PropTypes.bool.isRequired, // Validation for 'showQRCode'
  auth: PropTypes.shape({
    crmDacRoles: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of CRM roles, required
    safetyPlusDacRoles:
      PropTypes.arrayOf(PropTypes.string).isRequired, // Array of Safety Plus roles, required
    currentUser: PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.string).isRequired, // Array of user roles, required
    }).isRequired,
  }).isRequired,
};

// Default Props (if necessary)
DownloadPopupContainer.defaultProps = {};

DownloadPopupContent.propTypes = {
  showStoreButtons: PropTypes.bool.isRequired, // Boolean to show store buttons, required
  showQRCode: PropTypes.bool.isRequired, // Boolean to show QR code, required
  hrefAnroid: PropTypes.string.isRequired, // Link for Android download, required
  hrefios: PropTypes.string.isRequired, // Link for iOS download, required
  title: PropTypes.string.isRequired, // Title for the popup, required
  qrCode: PropTypes.string.isRequired, // QR code value, required
};

// Default Props (if applicable)
DownloadPopupContent.defaultProps = {};
