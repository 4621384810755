import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';
import ForwoodResources from '../ForwoodResources';
import styles from './styles.module.scss';
import ContactSupport from '../../Containers/ContactSupport';
import ForwoodDownload from '../../Containers/ForwoodDownload';

const Footer = ({ t, settings, getSettings, auth }) => {
  useEffect(() => {
    getSettings();
  }, []);
  const {  crmDacRoles, safetyPlusDacRoles, currentUser } = auth;

  const hasOneOfRole = rolesList => rolesList?.length > 0 && currentUser?.roles?.some(role => rolesList.includes(role)) || false;
  const hasCrmDacRoles = hasOneOfRole(crmDacRoles);
  const hasSafetyPlusDacRoles = hasOneOfRole(safetyPlusDacRoles);
  const renderSupportComponents = () => {
    const supportComponents = {
      needHelp: settings.contactSupport && <ContactSupport t={t} />,
      forwoodResources: <ForwoodResources t={t} isAdmin={false} />,
      downloadApp: settings.download && (hasCrmDacRoles || hasSafetyPlusDacRoles) && <ForwoodDownload t={t} />
    };

    return auth.enabledSupportButtons.map((button) => {
      const component = supportComponents[button];
      return component ? React.cloneElement(component, { key: button }) : null;
    });
  };

  return <Row className={styles.gridContainer}>{renderSupportComponents()}</Row>;
};

Footer.propTypes = {
  t: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  getSettings: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

export default Footer;
